import React from "react";
import LazyLoad from "vanilla-lazyload";

const lazyloadConfig = {elements_selector: ".lazy"};

if (!document.lazyLoadInstance) {
    document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
}

export class LazyImage extends React.Component {
    componentDidMount() {
        document.lazyLoadInstance.update();
    }

    componentDidUpdate() {
        document.lazyLoadInstance.update();
    }

    render() {
        const { alt, src, srcset, sizes, width, height, classes } = this.props;
        return (
            <img
                alt={alt}
                className={`lazy${classes ? ' ' + classes : ''}`}
                data-src={src}
                data-srcset={srcset}
                data-sizes={sizes}
                width={width}
                height={height}
            />
        );
    }
}

export default LazyImage;