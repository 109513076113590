import React, {Component} from 'react';
import {Stage, Layer, Image, Text, Group} from 'react-konva';
import ImageCustom from '../ImageCustom';
import './style.scss';

function UzerbarKonva(props) {
    return (
        <React.Fragment>
            <Stage width={props.width} height={props.height} ref={node => {
                props.setUzerbarRef(node);
            }}>
                <Layer>
                    <ImageCustom src={props.substrate} width="432" height="64" />
                    <ImageCustom src={props.strip.image} width="256" height="64" />
                    <ImageCustom src={props.badge.image} width="64" height="64" />
                    <ImageCustom src={props.mark.image} width="64" height="64" />
                    <ImageCustom src="/images/ranks/icon_rank_90.png" width="29" height="32" x="66" y="19" />
                    <Text text={props.nickname} fontSize="13" fill="#fff" x="103" y="26"/>
                    <Text text={props.clan} fontSize="11" fill="#ffe400" x="103" y="13"/>
                    <Text text={props.server} fontSize="11" fill="#fff" x="103" y="40"/>
                    <Text text={props.statLabels} fontSize="6.5" fill="#fff" x="299" y="14"/>
                    <Text text={props.stats} fontSize="6.5" fill="#ffe400" x="317" y="14"/>
                </Layer>
            </Stage>
        </React.Fragment>
    );
}

export default UzerbarKonva;