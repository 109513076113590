import React, {Component} from 'react';

import InfoForm from './components/InfoForm';
import Achievement from './components/Achievement';
import UzerbarKanva from './components/UzerbarKanva';
import TabContent from './components/TabContent';

import substrate from './img/substrate.png';
import './App.scss';

import {Container,Tabs, Tab, Row, Col, Button, Form} from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parameters: {
          stage: {
            width: 432,
            height: 64
          }
        },
        achievements: {},
        servers: {
          0: "Альфа",
          1: "Браво",
          2: "Чарли",
        },
        nickname: "",
        clan: "",
        rank: 0,
        server: 0,
        strip: 0,
        badge: 0,
        mark: 0,
        stats: {
            playTime: 0,
            PVEClass: 'Н/Д',
            PVEWins: 0,
            PVPCLass: 'Н/Д',
            PVPWins: 0,
            KD: '0.00',
        },
        showSearchInput: false,
        searchString: '',
        apiError: {},
    };
    this.ApiUrl = '/achievements.json';
    this.statLabels = `ОНЛ\nPVE\n\nPVP`;
    this.searchTimer = null;
    this.wfClasses = {
        'Rifleman': 'Штурмовик',
        'Medic': 'Медик',
        'Engineer': 'Инженер',
        'Recon': 'Снайпер',
    };
  }

  componentDidMount() {
    fetch(this.ApiUrl)
      .then(response => response.json())
      .then(json => {
        this.setState({achievements: json.achievements})
      });
  }

  downloadImage(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  setUzerbarRef = (node) => {
      this.uzerbarRef = node;
  };

  onUzerbarDownload = () => {
      this.downloadImage(this.uzerbarRef.toDataURL(), 'uzerbar.png');
  };

  onNicknameChange = (e) => {
    this.setState({nickname: e.target.value});
  };

  onClanChange = (e) => {
    this.setState({clan: e.target.value});
  };

  onChangeMark = (Id) => {
    this.setState({mark: Id});
  };

  onChangeBadge = (Id) => {
    this.setState({badge: Id});
  };

  onChangeStrip = (Id) => {
    this.setState({strip: Id});
  };

  onStatLoad = () => {
      fetch('https://warete.lh1.in/wf-api.php?name=' + this.state.nickname)
          .then(resp => {
              try {
                  return resp.json();
              }
              catch (e) {
                  this.setState({
                      apiError: {
                          message: e.message
                      }
                  });
              }
          })
          .then(json => {
              if (typeof json.code !== 'undefined' && json.code === 0)
              {
                  this.setState({
                      apiError: {
                        message: json.message
                      }
                  });
              }
              else
              {
                  const newState = {
                      stats: {
                          playTime: json.playtime_h,
                          PVEClass: this.wfClasses[json.favoritPVE].toUpperCase(),
                          PVEWins: json.pve_wins,
                          PVPCLass: this.wfClasses[json.favoritPVP].toUpperCase(),
                          PVPWins: json.pvp_wins + json.pvp_lost,
                          KD: json.pvp,
                      },
                      nickname: json.nickname,
                      clan: json.clan_name,
                      apiError: {},
                  };
                  this.setState(newState);
              }
          });
  };

  onTabChange = (key) => {
      if (key === 'info')
      {
          this.setState({showSearchInput: false});
      }
      else if (this.state.showSearchInput === false)
      {
          this.setState({showSearchInput: true});
      }
  };

  onSearch = (e) => {
      if (this.searchTimer)
      {
          clearTimeout(this.searchTimer);
      }
      const val = e.target.value;
      this.searchTimer = setTimeout(() => {
          this.setState({searchString: val});
      }, 700);
  };

  get server() {
    return `Сервер: ${this.state.servers[this.state.server]}`;
  }

  get currentMark() {
    return this.state.achievements.marks[this.state.mark];
  }

  get currentStrip() {
    return this.state.achievements.strips[this.state.strip];
  }

  get currentBadge() {
    return this.state.achievements.badges[this.state.badge];
  }

  get stats() {
      const {stats} = this.state;
      return `${stats.playTime} Ч.\n${stats.PVEClass}\n${stats.PVEWins}\n${stats.PVPCLass}\n${stats.PVPWins}\n${stats.KD}`;
  }

  get marks() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.marks)
      {
          return [];
      }
      if (val.length)
      {
          return this.state.achievements.marks.filter(item => (
              item.name.toLowerCase().includes(val) || item.description.toLowerCase().includes(val)
          ));
      }
      else
      {
          return this.state.achievements.marks;
      }
  }

  get strips() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.strips)
      {
          return [];
      }
      if (val.length)
      {
          return this.state.achievements.strips.filter(item => (
              item.name.toLowerCase().includes(val) || item.description.toLowerCase().includes(val)
          ));
      }
      else
      {
          return this.state.achievements.strips;
      }
  }

  get badges() {
      const {searchString} = this.state;
      const val = searchString.toLowerCase();
      if (!this.state.achievements.badges)
      {
          return [];
      }
      if (val.length)
      {
          return this.state.achievements.badges.filter(item => (
              item.name.toLowerCase().includes(val) || item.description.toLowerCase().includes(val)
          ));
      }
      else
      {
          return this.state.achievements.badges;
      }
  }

  render() {
    const {parameters} = this.state;
    return (
        <Container>
            {Object.keys(this.state.achievements).length && (
                <Row className="align-items-center my-3">
                    <Col md={7}>
                        <UzerbarKanva
                            width={parameters.stage.width}
                            height={parameters.stage.height}
                            substrate={substrate}
                            strip={this.currentStrip}
                            mark={this.currentMark}
                            badge={this.currentBadge}
                            nickname={this.state.nickname}
                            clan={this.state.clan}
                            server={this.server}
                            statLabels={this.statLabels}
                            stats={this.stats}
                            setUzerbarRef={this.setUzerbarRef}
                        />
                    </Col>
                    <Col md={5} className="pt-3 pt-md-0">
                        <Button variant="outline-success" block onClick={this.onUzerbarDownload}>Сохранить</Button>
                    </Col>
                </Row>
            )}
            {this.state.showSearchInput && (
                <Form>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Начните вводить название достижения..." onChange={this.onSearch}/>
                    </Form.Group>
                </Form>
            )}
            <Tabs justify defaultActiveKey="info" id="uzerbar-tabs"  onSelect={this.onTabChange}>
                <Tab eventKey="info" title="Общее">
                    <TabContent>
                        <InfoForm
                            onNicknameChange={this.onNicknameChange}
                            onClanChange={this.onClanChange}
                            onStatLoad={this.onStatLoad}
                            nickname={this.state.nickname}
                            clan={this.state.clan}
                            error={this.state.apiError}
                        />
                    </TabContent>
                </Tab>
                <Tab eventKey="strip" title="Нашивка">
                    <TabContent>
                        {
                            Object.keys(this.state.achievements).length &&
                            this.strips.map((item, index) =>
                                <Achievement
                                    key={index}
                                    id={index}
                                    onSelectAchievement={this.onChangeStrip}
                                    type="strip"
                                    active={this.state.strip === index}
                                    {...item}
                                />
                            )
                        }
                    </TabContent>
                </Tab>
                <Tab eventKey="badge" title="Жетон">
                    <TabContent>
                        {
                            Object.keys(this.state.achievements).length &&
                            this.badges.map((item, index) =>
                                <Achievement
                                    key={index}
                                    id={index}
                                    onSelectAchievement={this.onChangeBadge}
                                    type="badge"
                                    active={this.state.badge === index}
                                    {...item}
                                />
                            )
                        }
                    </TabContent>
                </Tab>
                <Tab eventKey="mark" title="Значок">
                    <TabContent>
                        {
                            Object.keys(this.state.achievements).length &&
                            this.marks.map((item, index) =>
                                <Achievement
                                    key={index}
                                    id={index}
                                    onSelectAchievement={this.onChangeMark}
                                    type="mark"
                                    active={this.state.mark === index}
                                    {...item}
                                />
                            )
                        }
                    </TabContent>
                </Tab>
            </Tabs>

            <div className="w-100 text-center">
                <span className="h4">Не забудьте поделиться с друзьями нашим сервисом!</span>
                <div
                    className="uSocial-Share my-2"
                    data-pid="c5a6de616ed0f34ef62bc1367198a588"
                    data-type="share"
                    data-options="round-rect,style1,default,absolute,horizontal,size32,eachCounter1,counter0,nomobile"
                    data-social="vk,fb,twi,ok,telegram,email"
                ></div>
            </div>
        </Container>
    );
  }
}

export default App;
