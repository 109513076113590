import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import './style.scss';
import LazyImage from '../LazyImage';

class Achievement extends Component {
    onClick = () => {
        this.props.onSelectAchievement(this.props.id);
    };

    render() {
        return (
            <React.Fragment>
                <Card onClick={this.onClick} className={this.props.active ? 'active' : ''}>
                    <Card.Body className="achievement row justify-content-center">
                        <div className="col-md-8">
                            <div className="achievement__title">{this.props.name}</div>
                            <div className="achievement__description text-muted">{this.props.description}</div>
                        </div>
                        <div className="col-md-4 text-center text-md-right">
                            <LazyImage src={this.props.image} alt={this.props.name} classes={`achievement__img img-${this.props.type}`}/>
                        </div>
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}
export default Achievement;